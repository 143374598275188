const brandData = [
  {
    id: 1,
    name: "Aero",
    images: ["/brand/101.jpg"],
  },
  {
    id: 2,
    name: "Barratt Sweets",
    images: ["/brand/102.jpg"],
  },
  {
    id: 3,
    name: "Bebeto Sweets",
    images: ["/brand/103.jpg"],
  },
  {
    id: 4,
    name: "Bonds Confectionery",
    images: ["/brand/104.jpg"],
  },
  {
    id: 5,
    name: "Burtons Biscuits",
    images: ["/brand/105.jpg"],
  },
  {
    id: 6,
    name: "Butterkist Popcorn",
    images: ["/brand/106.jpg"],
  },
  {
    id: 7,
    name: "Cadbury Chocolate",
    images: ["/brand/107.jpg"],
  },
  {
    id: 8,
    name: "Cheetos Crisps",
    images: ["/brand/108.jpg"],
  },
  {
    id: 9,
    name: "Chewits",
    images: ["/brand/109.jpg"],
  },
  {
    id: 10,
    name: "Diablo Sugar Free",
    images: ["/brand/1010.jpg"],
  },
  {
    id: 11,
    name: "Discos",
    images: ["/brand/1011.png"],
  },
  {
    id: 12,
    name: "Doritos",
    images: ["/brand/1012.jpg"],
  },
  {
    id: 13,
    name: "Fini",
    images: ["/brand/1013.jpg"],
  },
  {
    id: 14,
    name: "Fox's",
    images: ["/brand/1014.jpg"],
  },
  {
    id: 15,
    name: "Fruit-Tella",
    images: ["/brand/1015.jpg"],
  },
  {
    id: 16,
    name: "Galaxy",
    images: ["/brand/1016.jpg"],
  },
];

export default brandData;
