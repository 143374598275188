import React, { createContext, useState, useEffect } from "react";
import productData from "../Datas/productData";
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [shippingCost, setShippingCost] = useState(4.0);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (productId, quantity, color) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item.id === productId && item.color === color
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId && item.color === color
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        const newItem = {
          id: productId,
          color,
          quantity,
        };
        return [...prevItems, newItem];
      }
    });
  };

  const removeFromCart = (productId, color) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId || item.color !== color)
    );
  };

  const updateQuantity = (productId, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      )
    );
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        getProductById,
        shippingCost,
        setShippingCost,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
