import React, { useContext, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";
import "../css/main.css";
import productData from "../Datas/productData";
import brandData from "../Datas/brandData";
import { CartContext } from "../Component/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function Main() {
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const [buttonClickedStatus, setButtonClickedStatus] = useState({});

  const handleAddToCart = (productId) => {
    addToCart(productId, 1);
    setButtonClickedStatus((prevState) => ({
      ...prevState,
      [productId]: true,
    }));
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img alt="" src="/sweet/101.jpg" />
        </div>
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <h1>Buy Sweets & Snacks Online</h1>
            <div className="product-grid">
              {productData
                .filter((brand) => brand.id >= 1 && brand.id <= 15)
                .map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <Link to={`/shopDetails/${brand.id}`}>
                        <img alt="" src={brand.images[0]} />
                      </Link>
                      <h2>{brand.name}</h2>
                      <h4>{brand.price}</h4>
                      <div className="mainTopThree">
                        <div className="glsr-summary-stars22">
                          <div className="glsr-star-rating glsr-stars">
                            <span
                              className="glsr-star glsr-star-full"
                              aria-hidden="true"
                            ></span>
                            <span
                              className="glsr-star glsr-star-full"
                              aria-hidden="true"
                            ></span>
                            <span
                              className="glsr-star glsr-star-full"
                              aria-hidden="true"
                            ></span>
                            <span
                              className="glsr-star glsr-star-full"
                              aria-hidden="true"
                            ></span>
                            <span
                              className="glsr-star glsr-star-full"
                              aria-hidden="true"
                            ></span>
                          </div>
                          <div className="main_reviews_based_on">
                            ({brand.review}&nbsp;Reviews)
                          </div>
                        </div>
                      </div>
                      <button
                        className="to-cart-btn"
                        onClick={() => handleAddToCart(brand.id)}
                      >
                        Add To Basket
                        {buttonClickedStatus[brand.id] && (
                          <FontAwesomeIcon icon={faCheck} />
                        )}{" "}
                      </button>
                      <Link className="view-cart" to="/cartpage">
                        <a href="/cartpage">View basket</a>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* mid */}
          <div className="container-mid">
            <div className="cheese-club-banner">
              <div className="cheese-left">
                <div className="cheese-left-txt">
                  <img alt="" src="/sweet/102.jpg" />
                  <div className="pound-div">
                    <div className="join-cheese-club"> One Pound Sweets</div>
                    <div className="mainTopThree">
                      <div className="glsr-summary-stars22">
                        <div className="glsr-star-rating glsr-stars">
                          <span
                            className="glsr-star glsr-star-full"
                            aria-hidden="true"
                          ></span>
                          <span
                            className="glsr-star glsr-star-full"
                            aria-hidden="true"
                          ></span>
                          <span
                            className="glsr-star glsr-star-full"
                            aria-hidden="true"
                          ></span>
                          <span
                            className="glsr-star glsr-star-full"
                            aria-hidden="true"
                          ></span>
                          <span
                            className="glsr-star glsr-star-full"
                            aria-hidden="true"
                          ></span>
                        </div>
                        <div className="main_reviews_based_on">
                          17560 reviews
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cheese-right">
                <div className="wf-cell-all1">
                  <div className="wf-cell1">
                    <h4>David Bell</h4>
                    <div className="main_reviews">July 21, 2024</div>
                    <div className="glsr-summary-stars99">
                      <div className="glsr-star-rating glsr-stars99">
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <p className="wf-pp">Good</p>
                  </div>

                  <div className="wf-cell1">
                    <h4>Elizabeth McColl</h4>
                    <div className="main_reviews">July 21, 2024</div>
                    <div className="glsr-summary-stars99">
                      <div className="glsr-star-rating glsr-stars99">
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <p className="wf-pp">Highly recommend Delivered quickly</p>
                  </div>
                  <div className="wf-cell1">
                    <h4>Excellent.</h4>
                    <div className="main_reviews">July 27, 2024</div>
                    <div className="glsr-summary-stars99">
                      <div className="glsr-star-rating glsr-stars99">
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <p className="wf-pp">Always good. Thanks</p>
                  </div>
                  <div className="wf-cell1">
                    <h4>StevenB</h4>
                    <div className="main_reviews">July 29, 2024</div>
                    <div className="glsr-summary-stars99">
                      <div className="glsr-star-rating glsr-stars99">
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                        <span
                          className="glsr-star99 glsr-star-full"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <p className="wf-pp">Great seller - no issues to report.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*brand */}
          <div className="container-bot">
            <div className="product-carousel-container">
              <div className="pt-2">
                <h3>Brand</h3>
              </div>
              <div className="data-swiper">
                <div className="swiper-all">
                  {brandData.map((brand) => (
                    <div className="swiper-slide">
                      <img alt="" src={brand.images[0]} className="main-img" />
                      <h2>{brand.name}</h2>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* cheaps */}
          <div className="top-container">
            <h1>Brand New Snacks Just Added</h1>
            <div className="product-grid">
              {productData
                .filter((brand) => brand.id >= 16 && brand.id <= 25)
                .map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <Link to={`/shopDetails/${brand.id}`}>
                        <img alt="" src={brand.images[0]} />
                      </Link>
                      <h2>{brand.name}</h2>
                      <div className="now-pre">
                        <h4 className="pre-price">{brand.preprice}</h4>
                        <h4 className="now-price">{brand.price}</h4>
                      </div>

                      <button
                        className="to-cart-btn"
                        onClick={() => handleAddToCart(brand.id)}
                      >
                        Add To Basket
                        {buttonClickedStatus[brand.id] && (
                          <FontAwesomeIcon icon={faCheck} />
                        )}
                      </button>
                      <Link className="view-cart" to="/cartpage">
                        <a href="/cartpage">View basket</a>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* bot-des */}
          <div className="bot-descript">
            <div className="full-des">
              <h2>Online Retro Sweet Shop</h2>
              <p>
                One Pound Sweets is an online sweet shop selling all your
                childhood favourites from a low price of just £1 per bag. We
                have almost everything you can ever wish for in stock from parma
                violets, bonbons, fruit salads & black jacks.
              </p>
              <p>
                If you used to rush home from school and race to the corner shop
                to spend your left over dinner money on 1p chews or a 10p wham
                bar, then this is the website for you. We stock all the classic
                retro sweets from the 60s, 70s, 80s, 90s and 00s.
              </p>
              <p>
                We founded the website because of a serious addition to sweets
                and all things chocolate, and we knew the Great British public
                felt the same. So we decided to offer the very best retro sweets
                which you remember from your childhood and bring to them to the
                masses at a super-affordable price.
              </p>
              <p>
                We have nearly all our range of sweets available in 100g bags
                from as little as £1. This is great value and enables our
                customers to select a large selection of goodies without
                breaking the bank.
              </p>
              <p>
                Everyone has their favourite, the bosses are Haribo Rhubarb and
                Custard, he insists on trying a few from each batch for
                “quality” but really we all know what his game is! If your
                favourites used to be strawberry bonbons sweets, or how about
                classic cola cubes, or pear drops, or chewing nuts – we have
                them all.
              </p>
              <p>
                You can order your sweets from our website via a secure checkout
                and have them delivered to your door within a few days. This
                means you could be thinking about sweets on a Monday lunch time
                and by Wednesday you can be sat on your sofa wondering which of
                the 20 bags you are going to eat first! – And which you are
                going to hide from the kids too..
              </p>
              <p>
                We also stock a range of sweet jars and gifts that are perfect
                for events such as Mother’s or Father’s Day, Easter, Halloween
                and of course Christmas.
              </p>
              <p>
                So if you are looking to indulge in some retro-sugary goodness
                then please browse the website, choose your favourite sweets and
                chocolates, add them to your shopping bag and complete your
                order. We accept all major card payments as well as PayPal so it
                has never been easier to order some naughty treats.
              </p>
              <p>
                There are hundreds of online sweet shops springing up ever since
                lockdown but most of these are run from home and are not
                compliant with health and safety laws. We run our online
                business from a new huge 6000 sqft warehouse in Stoke on Trent
                and have a large team of pickers, packers and admin staff who
                work super hard to get your orders out as fast as possible.
              </p>
              <p>
                We have grown over the past 2 years to become one of the most
                reviewed online stores and have thousands of reviews on our
                Facebook and Google business pages. This means you can be
                assured of quality products and quality service. We post out
                thousands of orders every week and have an average review score
                of 4.9 out of 5.
              </p>
              <p>
                So if you are looking to buy some sweets then you will find
                everything you need on our website, checkout easily and securely
                and then have the parcel delivered to your door super fast.
              </p>
              <p>
                Plus, because you have read all this info we can give you a
                little discount on your order too – why not use promo code
                ‘sneaky15’ for 10% off your order when you spend £15 or more on
                sweets – we cannot say fairer than that can we?
              </p>
              <p>Why not check us out on Facebook too!</p>
              <h3>Do you sell a quarter of sweets?</h3>
              <p>
                The traditional way to buy sweets from a sweet shop back in the
                50s,60s,70s,80s and even 90s was to get ‘a quarter of’, this was
                1/4 of a pound in old fashioned weighing methods.
              </p>
              <p>
                A quarter of a pound or a half pound measure was the most
                typical requests for any shop keeper and this was the standard
                method of weighing out your tasty treats. This was the way to do
                it right up till the late 90s when we all went metric.
              </p>
              <p>
                Due to the dam EU we all went metic in this meant that you could
                no longer sell aquarterof sweets anymore and everything went
                into 100g / 200g weigh outs instead and this killed off a
                quarter of sweets sadly.
              </p>
              <p>
                Most of our bags of sweets are in 100g, 150g or 200g bags
                depending on the brand we sell. We do not hand pack any bags
                anymore they are all pre packed by the manufacturer so we are at
                the mercy of what they decide is the right weight for the £1
                price point.
              </p>
              <p>
                A lot of sweets are now 150g for £1 however some of the branded
                chocolate lines are now as low as 79g for £1 – due to the price
                of cocoa and sugar going up and up.
              </p>
              <p>
                Sadly we can not sell aquarterof sweets anymore however we do
                sell bigger sized bags sweets for £1 so actually we are often
                better value than if you bought from your local market stall. By
                buying direct from the maker we are able to get great deals and
                we can pass these cost savings on to you buy offering sweets
                such as Haribo at £1 price point all year round!
              </p>
              <p>
                A quarter of sweets which is 113g in new money was an easy way
                to weigh out treats on the old fashioned scales as this was the
                unit of measurements they used. Our director used to run an old
                fashioned sweet shop in the early 00s and had a classic old
                fashioned scale in the shop.
              </p>

              <h3>Can I Get My Candy Delivered?</h3>
              <p>
                We are an online store so getting your candy delivered to you is
                no problem for us as all orders are sent in the post via Hermes
                and generally arrive within 5 days of ordering.
              </p>
              <p>
                Loads of websites are springing up offering sweet delivery
                services and most are run from home and do not last very long.
                One Pound Sweets is a serious player in the online confectionary
                business and getting candy delivered to your door is our only
                job.
              </p>
              <p>
                Our website works like any other such as Handy Candy or Next,
                you browse the site, add the sweets you want to buy to your
                online shopping basket and then visit the checkout page. Once
                you are at the checkout page you can select which address you
                want your sweets delivery to go to and then pay via PayPal or
                card payment. We then receive your order and get it ready for
                delivery and few days later your candy delivered as arrived.
              </p>
              <p>
                You can be assured that your online sweets delivery will be
                picked and packed super fast and get to you safe and sound. We
                currently have over 8000 customer reviews and have had over
                85,000 orders so we should know what we are doing by now.
              </p>
              <p>
                If you are looking for The Sweet Store that offers best prices,
                top quality sweets and unbelievable service then you have found
                the right website. We live and breath sweets – in fact I am
                eating a Cadbury Medley chocolate bar right now as I write this
                load of text, which you are not really supposed to read it is
                just to help our SEO and get us to the top of Google for as many
                key phrases as possible. If you have read all the way down here
                then you are a bloody legend and deserve a prize – here is 15%
                off – using code MARKET15 on the shopping cart page.
              </p>
              <p>
                Loads of The Sweet Stores have opened up recently and we do not
                mind this as there are thousands of customers wanting to buy
                sweets online everyday and we can not supply them all. So
                whether you buy sweets from Retro Sweet Shop, Treasure Island
                Sweets, Amazon, eBay or any other online sweet shop we hope you
                enjoy your purchase – but maybe next time you should buy from
                One Pound Sweets instead!!
              </p>
              <h2>Pick and Mix Sweets Delivered – Pick n Mix Sweet Shop</h2>
              <p>
                So do you sell pick and mix? This is a question we get asked
                quiet a lot and to be honest we do not sell pick and mix sweets
                in the traditional large pouches that everyone else on the
                internet seems to sell – however we so sell hundreds of sweets
                and our prices work out a lot cheaper!
              </p>
              <p>
                Pick and Mix sweet shops are basically a rip off – £14-£18 for
                1kg of sweets! This is £1.40 – £1.80 per 100g, this is more
                expensive than most cinemas for sweets, and we all know they are
                a rip off too.
              </p>
              <p>
                You can get 14 bags of Haribo each weighing 140g for £14 which
                means you get almost 1kg more for the same price! So please stop
                buying sweets from pick and mix sweet shops online as they are
                so expensive.
              </p>
              <p>
                We have hundreds of treats to buy all wrapped in their own bags
                and sealed by the manufactures, so you know they are going to be
                super fresh and also free from any allergens which are not
                listed on the packet and also you will get the quantity you
                wanted.
              </p>
              <p>
                Our tasty treats are from the biggest names in the business –
                Haribo, Barratts, Swizzels, Cadbury, Galaxy and many many more!
              </p>
              <p>
                So at the moment we do not sell pick and mix sweets in the
                traditional form (big pouch) but you can simply make your own
                bag buy buying loads of different sweets from us and then
                tipping them in a big bowl – and you will need a big bowl as you
                will get twice as much!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
