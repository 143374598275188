import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Delivery() {
  return (
    <div className="main-container">
      <Header />
      <div className="grayMid">
        <p>Home/delivery</p>
      </div>
      <div className="PrivacyDiv">
        <h2>Delivery Information</h2>
        <p>We ship orders Monday to Friday and Sundays. </p>
        <p>
          We offer 3 speeds of delivery and offer free delivery on orders over
          £50
        </p>
        <h3>Express (Next Day: Order Before 2pm) – £6.00</h3>
        <p>
          This is available Monday to Thursday only. Place your order before 2pm
          on these days and you will receive your order the following day.
          Orders placed after 2pm will be shipped the following day.
        </p>
        <p>
          These orders will be sent via Fed-Ex or Amazon Shipping depending on
          the size and weight of the parcel.
        </p>
        <p>
          This option is not available to select on Friday, Saturday or Sunday.
        </p>
        <h3>Premium (1-2 Working Days) – £5.00</h3>
        <p>
          Place your order before 2pm Monday to Friday, and it will be shipped
          the same day and arrive within 24-48 hours.
        </p>
        <p>
          If you order on Saturday and before 2pm on Sunday it will be shipped
          on Sunday using Amazon next day option. This is not available to
          Northern Ireland, most of Scotland or for very large orders which are
          too big for the Amazon network.
        </p>
        <p>
          These orders will be shipped with Amazon Shipping or EVRi depending on
          the size and weight of the parcel.
        </p>
        <p>
          In most cases these orders arrive within 2 working days of ordering.
        </p>
        <h3>Standard (2-4 Working Days) – £4.00</h3>
        <p>
          These orders are processed within 1 working days of ordering and
          arrive within 2-3 after we have shipped it – so usually arrive within
          2-4 days from point of ordering.
        </p>
        <p>
          These orders are shipped with Amazon Shipping, EVRI or Royal Mail
          depending on the size and weight of the parcel
        </p>

        <h3>Free Delivery – Orders Over £50</h3>
        <p>If you spend over £50 we will ship your order for free. </p>
        <p>
          These orders are processed within 2 working days and shipped with
          either EVRi, Amazon or Royal Mail depending on the size and weight of
          the parcel.
        </p>
        <p>
          These orders will arrive within 2-4 days from ordering. If you require
          your order to arrive sooner you can choose one of the faster delivery
          options, but you will need to pay for this upgrade.
        </p>

        <h3>Terms and Conditions</h3>
        <p>
          All delivery times are estimated and not 100% guaranteed as delays can
          be caused once the parcel leaves us. We have no control over this and
          we do not offer refunds if the parcel arrives later than advertised.
        </p>
        <p>
          If you enter the wrong address and we ship your order we can not
          change it. We will try and request the parcel is stopped and returned
          to us but this is not available with all couriers. We offer no refunds
          if you enter the wrong address and the order is delivered.
        </p>
        <p>
          We may select a different courier based on weight, size and location
          of delivery.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Delivery;
