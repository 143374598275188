const productData = [
  {
    id: 1,
    name: "Barratt Milk Bottles 150g",
    description: [
      "Barratt Milk Bottles – The original ones which makes you hands all floury!",
      "These milk bottle gums are a classic retro sweet with a sweet milk flavour with dusting on top which makes your hands all sweet.",
      "Classic milk bottle shape these are loved by children and adults alike.",
      "Now in an even bigger 150g bag with a new price marked packet at £1.15",
    ],
    price: "£1.15",
    preprice: "£1.20",
    units: "51876",
    stock: "773",
    review: "62",
    images: ["/snack/101.jpg", "/snack/101a.jpg"],
  },
  {
    id: 2,
    name: "Chocolate Icy Cups 65g",
    description: [
      "Chocolate Icy cups in a foil wrapper – these are a classic from back in the day.",
      "These delicious little cups or chocolate goodness are contained in a colourful foil wrapper.",
      "The chocolate is so soft that it melts in the mouth and makes you devour the whole bag in one sitting.",
      "Grab a bag of Chocolate Icy Cups for just £1.25 – you get about 10 cups in each bag.",
      "These are now packed in our own branded Traditional Candy Co sweet bags.",
    ],
    price: "£1.45",
    preprice: "£2.00",
    units: "46776",
    stock: "17",
    review: "49",
    images: ["/snack/102.jpg"],
  },
  {
    id: 3,
    name: "Flying Saucers 30g",
    description: [
      "Flying saucers are a classic retro sweet from the 70s and 80s",
      "With their disc like shape and fizzy sherbet centre these are one of our most popular sweets.",
      "Multi-coloured melt in the mouth and totally delicious!",
      "You get around 30 saucers in 30g bag.",
    ],
    price: "£1.25",
    preprice: "£2.00",
    units: "45147",
    stock: "44",
    review: "53",
    images: ["/snack/103.jpg"],
  },
  {
    id: 4,
    name: "Haribo Rhubarb and Custard 90g",
    description: [
      "Haribo Rhubarb and Custard soft chewy fruit flavoured sweets – these are the bosses favourites!",
      "These little tubes or yellow, pink and green delight are fruit flavoured with sweet rhubarb and custard in that classic combination which makes for a mouth water eating feast.",
      "These sweets are super colourful and make an excellent gift as they look amazing in the bag and are so eye catching.",
      "These Haribo Rhubarb and Custard sweets have a been our best selling item since day 1 and we can see why as they are super delicious, great value and look amazing.",
      "These now come in our own Traditional Candy Company branded bags, but the sweets inside are the official Haribo ones.",
    ],
    price: "£1.00",
    preprice: "£2.20",
    units: "44152",
    stock: "94",
    review: "65",
    images: ["/snack/104.jpg"],
  },
  {
    id: 5,
    name: "Chocolate Cinder Toffee 100g",
    description: [
      "Chocolate cinder toffee / honeycomb",
      "These pieces of honeycomb are covered in thick chocolate.",
      "Bit like a crunchy bar but bigger, better and more tasty!",
      "Super popular with our customers.",
    ],
    price: "£1.50",
    preprice: "£1.99",
    units: "40978",
    stock: "37",
    review: "88",
    images: ["/snack/105.png"],
  },
  {
    id: 6,
    name: "Coconut Mushrooms 80g",
    description: [
      "Traditional coconut mushrooms",
      "These soft chewy sweets are shaped like a mushroom and are topped with flashed coconut.",
      "A soft and chewy centre is married with the sweet flavour of coconut to make a truly delicious treat.",
      "These are a classic from the 50s and 60s and loved by our customers, and are one of our best selling lines.",
      "These now come in our own brand Traditional Candy Co sweet bags.",
    ],
    price: "£1.35",
    preprice: "£1.99",
    units: "36476",
    stock: "84",
    review: "69",
    images: ["/snack/106.jpg"],
  },
  {
    id: 7,
    name: "Chocolate Raisins 85g",
    description: [
      "Chocolate raisins are a classic retro sweet and it is easy to see why they have stood the test of time – these are super juicy with a sweet chocolate flavour.",
      "These are a classic tuck shop sweet with a soft chocolate flavoured outer coating and a sweet raisin centre.",
      "Chewy and more-ish you had better get a few bags as they will be gone quick!",
      "Very good price here as part of the Jacks sweet range.",
    ],
    price: "£1.00",
    preprice: "£1.99",
    units: "35942",
    stock: "103",
    review: "49",
    images: ["/snack/107.jpg"],
  },
  {
    id: 8,
    name: "Pint Pots 90g",
    description: [
      "Classic beer inspired sweets in the shape of a classic British pint glass.",
      "Pint pots or beer bottles are one of our best selling sweets and we can taste why – these are delicious!",
      "These sweets are beer flavoured but unfortunately do not contain real beer!",
      "They really do have a strong beer flavour and are a real trip down memory lane.",
      "Pint pot sweets / beer pint sweets are so delicious we have to stop ourselves from demolishing a bag or 10 everyday. These pint pot beer flavoured sweets are big in size so you do not get a lot of sweets – so buy 5 or 20 bags at a time!",
      "These now come in our own brand Traditional Candy Co sweet bags.",
    ],
    price: "£1.00",
    preprice: "£1.99",
    units: "34787",
    stock: "161",
    review: "31",
    images: ["/snack/108.jpg"],
  },
  {
    id: 9,
    name: "Jazzies 90g",
    description: [
      "Classic chocolate flavoured button shaped sweets – often called jazzies or brown jazzies – have a hard crunchy topping.",
      "Jazzies are one of our most popular lines and it is easy to see why as Jazzies are super tasty with that crunchy topping.",
      "These classic sweets are super delicious and taste of milk chocolate,",
      "The same size as normal buttons they are great to munch on whilst watching TV.",
      "Similar to snowies, jazzies are brown in colour with lots of sprinkles on top. We sell them in a 90g bag now which are perfect for taking to the cinema or eating whilst watching Netflix or antiques roadshow.",
      "These are now sold in our own brand Traditional Candy Co sweet bags.",
    ],
    price: "£1.00",
    preprice: "£1.99",
    units: "34560",
    stock: "29",
    review: "43",
    images: ["/snack/109.jpg"],
  },
  {
    id: 10,
    name: "Sweet Peanuts 90g",
    description: [
      "Sweet peanuts are a hard boiled peanut shaped sweet with a distinctive peanut flavour.",
      "These now come in our own Traditional Candy Co branded bags!",
      "There is no actual peanut in these sweets but they do look remarkably like the real thing.",
      "Sweet peanuts are a favourite amongst the 30-60 age bracket but can be loved by young and old!",
      "These are one of our most popular lines and it is easy to see why – they are not available in most supermarkets or shops and so sweet peanuts are one of those sweets you do not see everyday – big seller so stock up!",
    ],
    price: "£1.40",
    preprice: "£1.99",
    units: "34355",
    stock: "59",
    review: "52",
    images: ["/snack/1010.jpg"],
  },
  {
    id: 11,
    name: "Fruit Salad Chews Stick Pack",
    description: [
      "Barratt Fruit Salad Chews – pineapple and raspberry flavoured penny sweets",
      "These classic and distinctive fruit salad chews are a penny mix favourite.",
      "Pineapple and lemon flavoured and individually wrapped these little chews unfortunately no longer cost just 1p, however the flavour and memories are still as strong as they were in the 80s and 90s",
      "Now sold in a stick pack like Starburst or Chewits, with each sweet individually wrapped.",
    ],
    price: "£0.80",
    preprice: "£1.99",
    units: "34263",
    stock: "128",
    review: "28",
    images: ["/snack/1011.jpg"],
  },
  {
    id: 12,
    name: "Barratt Black Jacks Single Pack",
    description: [
      "These classic and distinctive black jack chews are a penny mix favourite.",
      "Aniseed flavoured and individually wrapped these little chews unfortunately no longer cost just 1p, however the flavour and memories are still as strong as they were in the 80s and 90s",
      "Now sold in a stick pack like Starburst or Chewits, with each sweet individually wrapped.",
    ],
    price: "£0.80",
    preprice: "£1.22",
    units: "32789",
    stock: "69",
    review: "48",
    images: ["/snack/1012.jpg"],
  },
  {
    id: 13,
    name: "Snowies 90g",
    description: [
      "Classic white chocolate flavoured button shaped sweets with a hard crunchy topping.",
      "White Jazzies / Snowies are one of our most popular lines",
      "These classic sweets are super delicious and taste of white chocolate,",
      "The snowies are the same size as normal buttons they are great to munch on whilst watching TV.",
      "These are now sold in our own brand Traditional Candy Co sweet bags.",
    ],
    price: "£1.00",
    preprice: "£1.99",
    units: "31528",
    stock: "79",
    review: "33",
    images: ["/snack/1013.jpg"],
  },
  {
    id: 14,
    name: "Bonds Strawberry Laces 40g",
    description: [
      "Bonds Strawberry Laces in a 40g bag.",
      "Small snack size bag of laces, has around 10 laces in 30cm in length as rough estimate",
      "Also know as strawberry liquorice laces.",
    ],
    price: "£0.50",
    preprice: "£1.00",
    units: "30280",
    stock: "30",
    review: "21",
    images: ["/snack/1014.jpg"],
  },
  {
    id: 15,
    name: "Bristows Toffee Bonbons 150g",
    description: [
      "BRISTOW’S TOFFEE BONBONS – CHEWY CENTRE!",
      "Bristows are the only UK supplier of toffee centred bonbons!",
      "Toffee flavoured bonbons",
      "These bonbons are soft round balls of chewy goodness with the flavour of toffee.",
      "They are so juicy they leave your mouth tingling from the flavour!",
      "White in colour makes them idea for wedding favours or wedding sweet tables.",
    ],
    price: "£1.50",
    preprice: "£2.99",
    units: "30232",
    stock: "328",
    review: "38",
    images: ["/snack/1015.jpg"],
  },
  {
    id: 16,
    name: "Doritos Tangy Cheese 70g",
    description: [
      "Classic Doritos tangy cheese grab bag crisps",
      "These triangle shaped crisps are packed with cheese flavour and are super crunchy",
      "Toffee flavoured bonbonsBig grab bag for just £1.00 on sale",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "37",
    stock: "10",
    images: ["/cheaps/101.jpg"],
  },
  {
    id: 17,
    name: "Walkers Quavers BBQ 54g",
    description: [
      "Walkers Quavers BBQ Big £1.25 Sharing Bag",
      "Bristows are the only UK supplier of toffee centred bonbons!These are the new £1.25 price marked packets – all Walkers crisps are now moving to £1.25 per pack rather than £1.00",
      "Classic curled crisps with a zingy BBQ flavour",
      "These a new and very popular, a new twist on the classic Quaver.",
      "Loved by kids everywhere!",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "7",
    stock: "13",
    images: ["/cheaps/102.jpg"],
  },
  {
    id: 18,
    name: "Walkers Max Extra Flamin’ Hot 70g",
    description: [
      "NEW for 2024 – Walkers Max Extra Flamin’ Hot Crisps 70g",
      "These are extra spicy and under the new branding from Walkers",
      "Big crunchy ridged crisps with a super spicy flavour",
      "Can you handle the heat?",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "3",
    stock: "13",
    images: ["/cheaps/103.jpg"],
  },
  {
    id: 19,
    name: "Doritos Extra Flamin’ Hot 70g",
    description: [
      "NEW for 2024",
      "Walkers Doritos Extra Flamin’ Hot 70g",
      "These are a new super spicy flavour of Doritos",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "20",
    stock: "12",
    images: ["/cheaps/104.jpg"],
  },
  {
    id: 20,
    name: "Walkers Sensations Thai Sweet Chilli 65g",
    description: [
      "Walkers Sweet Chilli Sensations £1.25 sharing bag",
      "These are the new £1.25 price marked packets – all Walkers crisps are now moving to £1.25 per pack rather than £1.00",
      "These are the posh crisps from Walkers and these big crunchy crisps have a sweet chilli flavour",
      "We also stock the Roasted chicken and thyme flavour too.",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "9",
    stock: "14",
    images: ["/cheaps/105.jpg"],
  },
  {
    id: 21,
    name: "Walkers Wotsits Sweet &; Spicy Flamin’ Hot 60g",
    description: [
      "Wotsits Sweet & Spicy Flamin Hot Sharing Bag £1.25",
      "Walkers have now moved the £1 bags to be £1.25 price marked on the packet.",
      "These are the same as the classic Wotsits, but with no cheese and instead our flamin hot instead!",
      "Big and crunchy crisps that get all over your fingers, clothes, hands, mouth and floor – but they are worth it!",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "19",
    stock: "13",
    images: ["/cheaps/106.jpg"],
  },
  {
    id: 22,
    name: "Wotsits Prawn Cocktail 48g",
    description: [
      "Wotsits Prawn Cocktail crisps – NEW!",
      "Bristows are the only UK supplier of toffee centred bonbons!These are the classic Wotsits crisps but with a new prawn cocktail flavour!",
      "No cheese here just pure prawny goodness",
      "These are in a big 48g £1.25 price marked bag.",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "30",
    stock: "1",
    images: ["/cheaps/107.jpg"],
  },
  {
    id: 23,
    name: "Wotsits Crunchy Extra Flamin’ Hot 60g",
    description: [
      "NEW for 2024",
      "Walkers Wotsits Extra Flamin’ Hot Crisps 60g",
      "These are a new super spicy version of the Wotsits Crunchy brand.",
      "Even more spicy that the original flamin’ hot ones!",
    ],
    price: "£1.06",
    preprice: "£1.25",
    units: "67",
    stock: "14",
    images: ["/cheaps/108.jpg"],
  },
  {
    id: 24,
    name: "Vimto Fizzy Pencils 100g",
    description: [
      "Vimto Fizzy Pencils 100g",
      "These are little tube shaped sweets with a fizzy texture and delicious Vimto flavour",
      "Great for munching on the go!",
    ],
    price: "£1.00",
    preprice: "£1.05",
    units: "13",
    stock: "47",
    images: ["/cheaps/109.jpg"],
  },
  {
    id: 25,
    name: "Nutella & Go Chocolate & Hazlenut Pot",
    description: [
      "Nutella & Go Chocolate and Hazelnut Biscuit Pot",
      "This is basically Nutella’s version of a choc dip",
      "Featuring smooth Nutella chocolate and biscuit sticks to dunk into it!",
      "Great little snack for the kids.",
    ],
    price: "£1.45",
    preprice: "£2.99",
    units: "50",
    stock: "70",
    images: ["/cheaps/1010.jpg"],
  },
];

export default productData;
