import "../css/header.css";
import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSuitcaseRolling } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const navigate = useNavigate();
  const handleAddToCart = () => {
    navigate("/cartpage");
  };
  const { cartItems, getProductById } = useContext(CartContext);

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="leftImg">
            <img alt="" src="/sweet/sweet.png" />
          </div>
          <div className="rightT">
            <p className="cartItemCount">{cartItems.length}</p>
            <Link to="/cartpage" className="to-add">
              <FontAwesomeIcon
                icon={faSuitcaseRolling}
                size="2xl"
                onClick={handleAddToCart}
              />
            </Link>
          </div>
        </div>
        <div className="headerMid">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA">
                  SHOP
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/privacy">Refund </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact">Contact </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="firstDescription22">
          <div className="mainTopThree">
            <div className="main_reviews_based_on77">
              Use code&nbsp;<strong>SUMMER</strong>&nbsp; to get £5 off when you
              spend £25+
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
