import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo12">
            <div className="com-img">
              <img alt="" src="/sweet/foot.png" />
            </div>
          </div>

          <div className="comImfo">
            <ul>
              <h3 className="comImfoH3">Site Links</h3>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/privacy">Refund Policies</Link>
              </li>
              <li>
                <Link to="/delivery">Delivery Information</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Business</Link>
              </li>
            </ul>
          </div>
          <div className="comImfo">
            <h3 className="comImfoH3">Opening Hours</h3>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>Closed</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>Closed</td>
              </tr>
            </tbody>
          </div>
        </div>
        <div className="legal">
          <div className="footLeftBot">
            <div className="legalLeft">
              © The Traditional Candy Company Ltd Trading as One Pound Sweets
            </div>
            <div className="footer_bar_seperator">
              Trading Address: Unit 4, Alderflat Drive, Newstead Industrial
              Estate, Stoke-on-Trent, ST4 8HX | Company Number: 11193657 | Vat
              Number: 322252837
            </div>
            <div className="webdesign_by_perfect">
              <ul>
                <li>Amazon Store</li>
                <li>Ebay Store</li>
                <li>One Pound Sweets</li>
                <li>One Pound Crisps</li>
                <li>Snack Saver</li>
                <li>Traditional Candy Company</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
