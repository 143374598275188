import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    orderNumberOrPostcode: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const buttonStyle = {
    backgroundColor: isSubmitted ? "rgb(75, 182, 120)" : "#4384f5", // 根据isSubmitted状态设置背景颜色
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.orderNumberOrPostcode)
      errors.orderNumberOrPostcode = "Order Number/Postcode is required";
    if (!formData.message) errors.message = "Message is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // 表单数据有效，可以提交表单
      console.log("Form data:", formData);
      setIsSubmitted(true);
      setFormData({
        name: "",
        email: "",
        orderNumberOrPostcode: "",
        message: "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="main-container">
      <Header />
      <div className="grayMid">
        <p>Home/contact</p>
      </div>
      <div className="PrivacyDiv">
        <h2>Contact</h2>
        <p>
          If you need help or support with an order please use the contact form
          below and our team will respond within a few hours.
        </p>
        <p>
          Please read these FAQs before sending an email as often this answers
          your questions:
        </p>
        <p>
          <strong>
            ANSWERS TO MOST COMMON ISSUES – WE WON’T REPLY IF THE ANSWER IS
            BELOW:
          </strong>
        </p>
        <p>
          <strong>1: Where is my order?</strong>
          <br />
          You are welcome to cancel your order up until the point we have sent
          it to the courier. We send all orders at around 2pm each day so if it
          If you have ordered less than 5 days ago your order will be on its way
          – check your inbox for tracking emails sent by ourselves and the
          courier. <br />
          If it has been more than 5 days we will look into it for you.
        </p>
        <p>
          <strong>2: I am missing some items</strong>
          <br />
          If you have ordered a box of crisps the extra items will be inside
          this box so open the box.
          <br />
          Other missing items we will look into it for you.
        </p>
        <p>
          <strong>3: Can I change my address? </strong>
          <br />
          If you need to change your address reply with the new address
          immediately.
          <br />
          We ship priority orders very quickly, often within an hour of ordering
          during office hours, so if the address is wrong and we have already
          shipped it we can not change this.
        </p>
        <p>
          <strong>4: My parcel has been delivered but I haven’t got it</strong>
          <br />
          If the parcel has been delivered but you believe you do not have it
          then first check the tracking email from the courier as it will show a
          photo of where it was delivered.
          <br />
          Check with your neighbours who may have received it for you, and check
          in secure locations around your property.
          <br />
          If you still believe you don’t have the parcel please reply and we
          will look into this
        </p>

        <p>
          <strong>5: I have damaged items </strong>
          <br />
          Please send and email with images of the damaged items so we can
          resolve for you quicker to hello service@onepoundsweets.xyz
        </p>
        <p>
          <strong>6: You want free sweets for an event</strong>
          <br />
          We do not offer free sweets for events as we get literally hundreds of
          requests each month. Emails about this will not be responded to, but
          good luck with your event.
        </p>
        <h2>You still need help? Email us below.</h2>
        <form onSubmit={handleSubmit} className="form-privacy">
          <header className="css-dspo5n">
            <h1>Contact us</h1>
            <p>
              If you have any questions or queries about your order, or anything
              else please use the contact form below.
            </p>
          </header>
          <div className="css-y79bgc">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {formErrors.name && <p>{formErrors.name}</p>}
          </div>
          <div className="css-y79bgc">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && <p>{formErrors.email}</p>}
          </div>
          <div className="css-y79bgc">
            <label>Order Number/Postcode:</label>
            <input
              type="text"
              name="orderNumberOrPostcode"
              value={formData.orderNumberOrPostcode}
              onChange={handleChange}
              required
            />
            {formErrors.orderNumberOrPostcode && (
              <p>{formErrors.orderNumberOrPostcode}</p>
            )}
          </div>
          <div className="css-y79bgc">
            <label>Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            {formErrors.message && <p>{formErrors.message}</p>}
          </div>
          <button
            type="submit"
            className="btn-privacy"
            style={buttonStyle}
            disabled={isSubmitted}
          >
            {isSubmitted ? "Success!" : "Send"}
          </button>
          <footer className="css-j2qwv5">
            <div>
              <span>Powered by</span>
              <img alt="" src="/sweet/helpdesk.svg" />
            </div>
          </footer>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
